<template>
  <div class="breakouts">
    <div class="grid two-columns">
      <div class="grid-item">
        <InputRadio title="Durchbruch Links" :items="breakthroughLeftItems"/>
      </div>

      <div v-if="options.breakthroughLeft" class="grid-item">
        <InputNumber
            title="Breite Links"
            option="widthLeft"
            :step="1"
            info-text="Max. 0,40 Meter"/>
      </div>
    </div>
    <hr>
    <div class="grid two-columns">
      <div class="grid-item">
        <InputRadio title="Durchbruch Mitte" :items="breakthroughMiddleItems"/>
      </div>

      <div v-if="options.breakthroughMiddle" class="grid-item">
        <InputNumber
            title="Breite Mitte"
            option="widthMiddle"
            :step="1"
            info-text="Max. 0,40 Meter"/>
      </div>
    </div>
    <hr>
    <div class="grid two-columns">
      <div class="grid-item">
        <InputRadio title="Durchbruch Rechts" :items="breakthroughRightItems"/>
      </div>

      <div v-if="options.breakthroughRight" class="grid-item">
        <InputNumber
            title="Breite Rechts"
            option="widthRight"
            :step="1"
            info-text="Max. 0,40 Meter"/>
      </div>
    </div>
  </div>
</template>

<script>
import InputRadio from "@/components/InputRadio";
import InputNumber from "@/components/InputNumber";
import {mapGetters} from "vuex";
export default {
  name: "Breakouts",
  components: {InputNumber, InputRadio},
  data() {
    return {
      breakthroughLeftItems: [
        { label: 'Kein Durchbruch', option: 'breakthroughLeft', value: false },
        { label: 'Durchbruch Links hinzufügen', option: 'breakthroughLeft', value: true }
      ],
      breakthroughMiddleItems: [
        { label: 'Kein Durchbruch', option: 'breakthroughMiddle', value: false },
        { label: 'Durchbruch Mitte hinzufügen', option: 'breakthroughMiddle', value: true }
      ],
      breakthroughRightItems: [
        { label: 'Kein Durchbruch', option: 'breakthroughRight', value: false },
        { label: 'Durchbruch Mitte hinzufügen', option: 'breakthroughRight', value: true }
      ]
    }
  },
  computed: {
    ...mapGetters(['options'])
  }
};
</script>

<style lang="scss">
  .breakouts {
    .grid {
      @media (max-width: $width-tablet) {
        grid-template-columns: 1fr;
        grid-row-gap: 24px;
      }
    }

    hr {
      width: 100%;
      height: 1px;
      margin: 24px 0 24px 0;

      background-color: #CAD3D9;
      border: none;
    }
  }
</style>